import { DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Sterilisation Data", "create-button-text": "Add Method Code", "ph-search-by": "Search by Method Code, Method Type", "search-by": "Method,Code", "has-delete": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "Method", attrs: { "base-url": "/sterilization-specifications/data-entries", "data-index": "method", "title": "Method Type", "width": 296, "sorter": true } }), _c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Method Code", "width": 202, "sorter": true } }), _c("text-field", { key: "StackingPattern", attrs: { "data-index": "stackingPattern", "title": "Stacking Pattern", "sorter": true } }), _c("text-field", { key: "IssueDate", attrs: { "data-index": "issueDate", "parse": _vm.parseIssueDate, "title": "Issue Date", "width": 273, "sorter": true } }), _c("tag-field", { key: "IsDraft", attrs: { "color": "blue", "data-index": "isDraft", "parse": _vm.parseStatus, "title": "Status", "width": 72 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "DataEntry",
  inject: ["can"],
  data() {
    return {
      DELETE_PERMISSION
    };
  },
  methods: {
    parseIssueDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    },
    parseStatus(value) {
      return value ? "Draft" : void 0;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListDataEntry = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-data-entry" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "sterilization.data-entries", "api-url": _vm.apiUrl, "create-route": "/sterilization-specifications/data-entries/create-data-entry", "filter-route": "/sterilization-specifications/data-entries/advanced-search", "edit-route": "/sterilization-specifications/data-entries/:id", "page": _vm.page } }, [_c("list-data-entry")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListDataEntry
  },
  data() {
    return {
      apiUrl,
      page: STERILIZATION_SPECIFICATIONS_DATA_ENTRY,
      itemsMenu: [
        {
          key: "dataentry",
          title: "Data Entry",
          path: ""
        },
        {
          key: "sterilisationdata",
          title: "Sterilisation Data",
          path: "/sterilization-specifications/data-entries"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
